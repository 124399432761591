import { useLocation } from "react-router-dom";
import PortfolioTable from "../components/Portfolio/PortfolioTable";
import PortfolioChart from "../components/Portfolio/PortfolioChart";
import { useEffect, useState } from "react";
import { getPortfolio } from "../network/TableApi/tableApi";
import { SinglePortfolioRes } from "../network/TableApi/tableInterfaces";

interface IProps {}

const PortfolioPage = (props: IProps) => {
  const [name, setName] = useState<string>("Название робота");
  const [percMode, setPercMode] = useState<boolean>(true);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const id = searchParams.get("id");

  useEffect(() => {
    getPortfolio({ id: id || "" }).then((res: SinglePortfolioRes) => {
      setName(res.data.name || "");
      setPercMode(res.data.isOnlyPerc);
    });
  }, []);
  return (
    <div className="defaultLayout">
      <div className=" mb-4">
        <p>
          Портфель <b>{name}</b>
        </p>
      </div>
      {id && (
        <div>
          <PortfolioTable id={id} external={true} percMode={percMode} />
          <div className="flex flex-col mt-10">
            <p className="mb-4">Графики за все время</p>
            <PortfolioChart
              id={id}
              type={"amountCompound"}
              desc="Сложный процент"
            />
            <PortfolioChart
              id={id}
              type={"factInc"}
              desc="Фактический процент"
            />
            {!percMode && (
              <PortfolioChart id={id} type={"amountMins"} desc="Заработано" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfolioPage;
