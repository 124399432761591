import { useEffect, useState } from "react";
import {
  SinglePortfolioRes,
  TableOverallRes,
} from "../../network/TableApi/tableInterfaces";
import {
  changePercMode,
  getPortfolio,
  getPortfolioAccTable,
  getPortfolioAllTable,
} from "../../network/TableApi/tableApi";
import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { formatDate } from "../../utils/functions";
import { Link } from "react-router-dom";
import { createPath } from "../../utils/linkHandler";
import AppRoutes from "../../structure/routes";

interface IProps {
  id: string;
  external?: boolean;
  percMode?: boolean;
}

const PortfolioTable = (props: IProps) => {
  const [tableData, setTableData] = useState<Array<TableOverallRes>>();
  const [all, setAll] = useState<any>([]);
  const [money, setMoney] = useState<boolean>(false);
  const [percMode, setPercMode] = useState<boolean>();

  useEffect(() => {
    getPortfolioAccTable({ id: props.id }).then((res) => {
      setTableData(res);
    });
    getPortfolio({ id: props.id }).then((res: SinglePortfolioRes) => {
      setPercMode(res.data.isOnlyPerc);
    });
    getPortfolioAllTable({ id: props.id }).then((data) => {
      setAll(data);
    });
  }, [props.id]); // Обновление данных при изменении props.id

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPercMode(e.target.checked);
    changePercMode({ mode: e.target.checked, id: props.id.toString() });
  }

  const getColor = (value: number) => {
    return value >= 0 ? "#3ac47d" : "#d92550";
  };

  const renderedRows = tableData?.map((row) => {
    console.log("Row Data:", row); // Для отладки
    const rev = [...row.orders.overall].reverse();
    const revM = [...row.orders.month].reverse();
    const revD = [...row.orders.today].reverse();

    return (
      <TableRow
        key={row.account}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {props.external && (
            <b>
              {row.name ? `${row.name} ${row.account.slice(-4)}` : row.account}
            </b>
          )}
          {!props.external && (
            <Link
              className="text-blue-500 underline"
              to={createPath({
                route: AppRoutes.RobotPage,
                args: [{ key: "id", value: row.account }],
              })}
            >
              <b>
                {row.name
                  ? `${row.name} ${row.account.slice(-4)}`
                  : row.account}
              </b>
            </Link>
          )}
        </TableCell>
        <TableCell>
          {formatDate(row.orders.overall[0].CloseTime.split("T")[0])}
        </TableCell>
        <TableCell
          align="right"
          style={{ color: getColor(rev[0]?.factInc ?? 0) }}
        >
          {money
            ? rev[0]
              ? `$${(rev[0].amountMins / 100).toFixed(2)}`
              : "$0.00"
            : rev[0]
            ? `${rev[0].factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
        <TableCell
          align="right"
          style={{ color: getColor(rev[0]?.amountCompound ?? 0) }}
        >
          {money
            ? rev[0]
              ? `$${(rev[0].amountMins / 100).toFixed(2)}`
              : "$0.00"
            : (rev[0].amountCompound * 100).toFixed(2) + "%"}
        </TableCell>
        <TableCell
          align="right"
          style={{ color: getColor(revM[0]?.factInc ?? 0) }}
        >
          {money
            ? revM[0]
              ? `$${(revM[0].amountMins / 100).toFixed(2)}`
              : "$0.00"
            : revM[0]
            ? `${revM[0].factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
        <TableCell
          align="right"
          style={{ color: getColor(revD[0]?.factInc ?? 0) }}
        >
          {money
            ? revD[0]
              ? `$${(revD[0].amountMins / 100).toFixed(2)}`
              : "$0.00"
            : revD[0]
            ? `${revD[0].factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      {props.external && !props.percMode && (
        <div className="flex">
          <FormControlLabel
            value="start"
            checked={money}
            onChange={() => {
              setMoney(!money);
            }}
            control={<Switch color="primary" />}
            label="Показывать в деньгах: "
            labelPlacement="start"
          />
        </div>
      )}
      {!props.external && (
        <div className="flex justify-between">
          <div>
            <FormControlLabel
              value="start"
              checked={money}
              onChange={() => {
                setMoney(!money);
              }}
              control={<Switch color="primary" />}
              label="Показывать в деньгах: "
              labelPlacement="start"
            />
          </div>
          <div className="flex flex-col gap-4 ">
            <div>
              <p
                className="cursor-pointer underline text-green-700"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${
                      window.location.origin
                    }/portfolio?id=${props.id.toString()}`
                  );
                }}
              >
                {`${
                  window.location.origin
                }/portfolio?id=${props.id.toString()}`}
              </p>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={percMode}
                    onChange={handleChange}
                    name="percMode"
                    color="primary"
                  />
                }
                label="Процентный режим"
              />
            </div>
          </div>
        </div>
      )}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Название счета</b>
              </TableCell>
              <TableCell>
                <b>Дата старта</b>
              </TableCell>
              <TableCell align="right">
                <b>Фактический доход</b>
              </TableCell>
              <TableCell align="right">
                <b>Абсолютный доход</b>
              </TableCell>
              <TableCell align="right">
                <b>Фактический доход за месяц</b>
              </TableCell>
              <TableCell align="right">
                <b>Фактический доход за день</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderedRows}
            {all.data && (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <b>Итого</b>
                </TableCell>
                <TableCell>~</TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: getColor(
                      all.data.overall[all.data.overall.length - 1]?.factInc ??
                        0
                    ),
                  }}
                >
                  {money
                    ? all.data.overall
                      ? `$${all.data.overall[
                          all.data.overall.length - 1
                        ].amountMins.toFixed(2)}`
                      : "$0.00"
                    : all.data.overall
                    ? `${all.data.overall[
                        all.data.overall.length - 1
                      ].factInc.toFixed(2)}%`
                    : "0%"}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: getColor(
                      all.data.overall[all.data.overall.length - 1]
                        ?.amountCompound ?? 0
                    ),
                  }}
                >
                  {money
                    ? all.data.overall
                      ? `$${all.data.overall[
                          all.data.overall.length - 1
                        ].amountMins.toFixed(2)}`
                      : "$0.00"
                    : all.data.overall
                    ? `${(
                        all.data.overall[all.data.overall.length - 1]
                          .amountCompound * 100
                      ).toFixed(2)}%`
                    : "0%"}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: getColor(
                      all.data.month[all.data.month.length - 1]?.factInc ?? 0
                    ),
                  }}
                >
                  {money
                    ? all.data.month
                      ? `$${(
                          all.data.month[all.data.month.length - 1].amountMins /
                          100
                        ).toFixed(2)}`
                      : "$0.00"
                    : all.data.month
                    ? `${all.data.month[
                        all.data.month.length - 1
                      ].factInc.toFixed(2)}%`
                    : "0%"}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: getColor(
                      all.data.today[all.data.today.length - 1]?.factInc ?? 0
                    ),
                  }}
                >
                  {money
                    ? all.data.today[all.data.today.length - 1]
                      ? `$${(
                          all.data.today[all.data.today.length - 1].amountMins /
                          100
                        ).toFixed(2)}`
                      : "$0.00"
                    : all.data.today[all.data.today.length - 1]
                    ? `${all.data.today[
                        all.data.today.length - 1
                      ].factInc.toFixed(2)}%`
                    : "0%"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PortfolioTable;
