import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";
import { Portfolio } from "../../network/TableApi/tableInterfaces";

interface IProps {
  portfolios: Array<Portfolio>;
  selected: string;
  onChange: (value: string) => void;
}

const ProtfolioSelector = (props: IProps) => {
  const [portfolio, setPortfolio] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    props.onChange(event.target.value);
    setPortfolio(event.target.value);
  };

  const renderedMenu = props.portfolios.map((el) => {
    return <MenuItem value={el.id}>{el.name}</MenuItem>;
  });

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 160 }}>
        <InputLabel id="demo-simple-select-autowidth-label">
          {props.selected}
        </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={portfolio}
          onChange={handleChange}
          autoWidth
          label="Age"
        >
          {renderedMenu}
        </Select>
      </FormControl>
    </div>
  );
};

export default ProtfolioSelector;
