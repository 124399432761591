import { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import { getIncomeData } from "../network/SumTableApi/SumTableApi";
import {
  AccountIncomeData,
  GetIncomeResponse,
  OverallMonthlyDetail,
  OverallYearlyDetail,
} from "../network/SumTableApi/SumTableInterfaces";

const YearlyIncome = () => {
  const [tableData, setTableData] = useState<AccountIncomeData[]>([]);
  const [overallMonthly, setOverallMonthly] = useState<OverallMonthlyDetail[]>(
    []
  );
  const [overallYearly, setOverallYearly] = useState<OverallYearlyDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [showPercent, setShowPercent] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    getIncomeData()
      .then((res: GetIncomeResponse) => {
        setTableData(res.data);
        setOverallMonthly(res.overallMonthly);
        setOverallYearly(res.overallYearly);
        setLoading(false);
      })
      .catch((err) => {
        setError("Ошибка загрузки данных");
        setLoading(false);
      });
  }, [selectedYear]);

  const getTextColor = (value: number) => {
    if (value === 0) return "#808080";
    return value > 0 ? "#3ac47d" : "#d92550";
  };

  // Получаем все уникальные месяцы из данных
  const activeMonths = Array.from(
    new Set(
      tableData
        .flatMap((row) => row.details.map((detail) => detail.month))
        .filter((month) => {
          const monthlyDetails = tableData.some((row) =>
            row.details.some(
              (d) => d.month === month && d.year === selectedYear
            )
          );
          return monthlyDetails;
        })
    )
  ).sort((a, b) => a - b);

  // Рендерим строки таблицы
  const renderedRows = tableData.map((row) => {
    const incomeByMonth = (month: number) => {
      const detail = row.details.find(
        (d) => d.month === month && d.year === selectedYear
      );
      if (!detail) return "0";
      const value = showPercent
        ? parseFloat(detail.incomePercentage)
        : parseFloat(detail.netProfit) / 100; // Делим на 100
      const formattedValue = showPercent
        ? `${value.toFixed(2)} %`
        : `${value.toFixed(2)} $`;
      return (
        <span style={{ color: getTextColor(value) }}>{formattedValue}</span>
      );
    };

    // Получаем данные общего дохода за год
    const overallDetail = row.overall.find((o) => o.year === selectedYear);

    const yearlyIncome = overallDetail
      ? showPercent
        ? parseFloat(overallDetail.incomePercentage)
        : parseFloat(overallDetail.netProfit) / 100 // Делим на 100
      : 0;

    const formattedYearlyIncome = showPercent
      ? `${yearlyIncome.toFixed(2)} %`
      : `${yearlyIncome.toFixed(2)} $`;

    return (
      <TableRow
        key={row.account}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Link
            className="text-blue-500 underline"
            to={createPath({
              route: AppRoutes.RobotPage,
              args: [{ key: "id", value: row.account }],
            })}
          >
            <b>
              {row.name ? `${row.name} ${row.account.slice(-4)}` : row.account}
            </b>
          </Link>
        </TableCell>
        {activeMonths.map((month) => (
          <TableCell key={month} align="right">
            {incomeByMonth(month)}
          </TableCell>
        ))}
        <TableCell align="right">
          <span style={{ color: getTextColor(yearlyIncome) }}>
            {formattedYearlyIncome}
          </span>
        </TableCell>
      </TableRow>
    );
  });

  // Обновляем функцию для отображения общей статистики по месяцам
  const overallIncomeByMonth = (month: number) => {
    const detail = overallMonthly.find(
      (d) => d.month === month && d.year === selectedYear
    );

    if (!detail) return "0";

    const value = showPercent
      ? parseFloat(detail.incomePercentage)
      : parseFloat(detail.netProfit) / 100;

    const formattedValue = showPercent
      ? `${value.toFixed(2)} %`
      : `${value.toFixed(2)} $`;

    return <span style={{ color: getTextColor(value) }}>{formattedValue}</span>;
  };

  // Обновляем расчет общего годового дохода
  const overallYearlyIncome = () => {
    const detail = overallYearly.find((d) => d.year === selectedYear);

    if (!detail) return 0;

    const value = showPercent
      ? parseFloat(detail.incomePercentage)
      : parseFloat(detail.netProfit) / 100;

    return value;
  };

  const overallYearlyValue = overallYearlyIncome();

  const formattedOverallYearlyIncome = showPercent
    ? `${overallYearlyValue.toFixed(2)} %`
    : `${overallYearlyValue.toFixed(2)} $`;

  const overallRow = (
    <TableRow key="overall" className="bg-gray-300">
      <TableCell component="th" scope="row">
        <b>Общий итог</b>
      </TableCell>
      {activeMonths.map((month) => (
        <TableCell key={month} align="right">
          {overallIncomeByMonth(month)}
        </TableCell>
      ))}
      <TableCell align="right">
        <span style={{ color: getTextColor(overallYearlyValue) }}>
          {formattedOverallYearlyIncome}
        </span>
      </TableCell>
    </TableRow>
  );

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <FormControl>
        <InputLabel id="year-select-label">Год</InputLabel>
        <Select
          labelId="year-select-label"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value as number)}
        >
          {Array.from({ length: 10 }, (_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Switch
            checked={showPercent}
            onChange={() => setShowPercent(!showPercent)}
          />
        }
        label="Показать в процентах"
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Название счета</b>
              </TableCell>
              {activeMonths.map((month) => (
                <TableCell key={month} align="right">
                  <b>
                    Доход (
                    {new Date(0, month - 1).toLocaleString("ru-RU", {
                      month: "long",
                    })}
                    )
                  </b>
                </TableCell>
              ))}
              <TableCell align="right">
                <b>Доход общий</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderedRows}
            {overallRow}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default YearlyIncome;
