import { Button, Modal, Box, TextField } from "@mui/material";
import { useState } from "react";
import { updatePortfolio } from "../../network/TableApi/tableApi";
import { UserAccount } from "../../types/component";
import PortfolioCheckbox from "./PortfolioCheckbox";
import { EditPortfolioRes } from "../../network/TableApi/tableInterfaces";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface IProps {
  loaded: Array<EditPortfolioRes>;
  name: string;
  accounts: Array<UserAccount>;
  id: string;
  onCreate: (value: boolean) => void;
}

const EditPortfolioModal = (props: IProps) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string>(props.name);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const accs = props.loaded.map((account) => account.account.account);
  const [selected, setSelected] = useState<Array<string>>(accs);
  function checkCondition(): boolean {
    if (name.length === 0) {
      return false;
    }
    if (!selected[0]) {
      return false;
    }
    return true;
  }

  function handleCreate() {
    updatePortfolio({ accounts: selected, id: props.id, name: name }).then(
      (r) => {
        setName("");
        setSelected([]);
        handleClose();
        if (r.success) {
          props.onCreate(true);
        }
      }
    );
  }
  const renderedAccounts = props.accounts.map((account) => {
    return (
      <PortfolioCheckbox
        name={account.name || account.account}
        checked={accs.includes(account.account) ? true : false}
        onChange={(e) => {
          if (e) {
            setSelected([...selected, account.account]);
          } else {
            setSelected(selected.filter((item) => item !== account.account));
          }
        }}
      />
    );
  });

  return (
    <div>
      <Button onClick={handleOpen}>Редактировать портфель</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="flex gap-4">
          <Box sx={style}>
            <TextField
              label="Название портфеля"
              variant="standard"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            {renderedAccounts}
            <Button
              variant="contained"
              disabled={!checkCondition()}
              onClick={() => {
                handleCreate();
              }}
            >
              Сохранить
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default EditPortfolioModal;
