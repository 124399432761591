import { useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";

interface AccountDetail {
  month: number;
  year: number;
  drawdown?: number;
}

interface AccountMarginAndDrawdown {
  account: string;
  name?: string;
  details: AccountDetail[];
}

interface Props {
  data: AccountMarginAndDrawdown[];
  selectedYear: number;
}

const getDrawdownCellColor = (drawdown: number) => {
  if (drawdown === 0) return "#808080";
  if (drawdown > -20) return "#3ac47d";
  if (drawdown >= -30) return "#f7b924";
  return "#d92550";
};

const YearlyDrawdown = ({ data, selectedYear }: Props) => {
  const activeMonths = useMemo(() => {
    const monthsSet = new Set<number>();
    data.forEach((row) => {
      row.details.forEach((detail) => {
        if (detail.year === selectedYear && detail.drawdown !== 0) {
          monthsSet.add(detail.month);
        }
      });
    });
    return Array.from(monthsSet).sort((a, b) => a - b);
  }, [data, selectedYear]);

  const getMinDetailDrawdown = (details: AccountDetail[]) => {
    return details.length
      ? Math.min(...details.map((detail) => detail.drawdown ?? 0))
      : 0;
  };

  const renderedRows = useMemo(() => {
    return data.map((row) => {
      const drawdownByMonth = (month: number) => {
        const monthlyDetails = row.details.filter(
          (detail) => detail.month === month && detail.year === selectedYear
        );
        return getMinDetailDrawdown(monthlyDetails);
      };

      return (
        <TableRow key={row.account}>
          <TableCell component="th" scope="row">
            <Link
              className="text-blue-500 underline"
              to={createPath({
                route: AppRoutes.RobotPage,
                args: [{ key: "id", value: row.account }],
              })}
            >
              <b>
                {row.name
                  ? `${row.name} ${row.account.slice(-4)}`
                  : row.account}
              </b>
            </Link>
          </TableCell>
          {activeMonths.map((month) => {
            const drawdown = drawdownByMonth(month);
            const color = getDrawdownCellColor(drawdown);
            return (
              <TableCell
                key={month}
                align="right"
                sx={{ color, fontWeight: "bold" }}
              >
                {drawdown} %
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  }, [data, activeMonths, selectedYear]);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="drawdown table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Название счета</b>
              </TableCell>
              {activeMonths.map((month) => (
                <TableCell key={month} align="right">
                  <b>
                    Максимальная просадка (
                    {new Date(0, month - 1).toLocaleString("ru-RU", {
                      month: "long",
                    })}
                    )
                  </b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderedRows}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default YearlyDrawdown;
