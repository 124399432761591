import TableEndpoints from "../../structure/tableEndpoints";
import { request } from "../mainRequest";
import {
  GetIncomeResponse,
  GetMarginAndDrawdownReq,
  GetMarginAndDrawdownRes,
} from "./SumTableInterfaces";

export function getMarginAndDrawdown(
  data?: GetMarginAndDrawdownReq
): Promise<GetMarginAndDrawdownRes> {
  return request(TableEndpoints.GetMarginAndDrawdown, data);
}

export function getIncomeData(): Promise<GetIncomeResponse> {
  return request(TableEndpoints.GetIncome);
}
