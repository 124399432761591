import { useEffect, useState } from "react";
import TodayTable from "../components/TodayTable";
import { Order } from "../network/TableApi/tableInterfaces";
import { getTodayOverall } from "../network/TableApi/tableApi";

interface IProps {}

const formatDate = (date: Date) => {
  const d = new Date(date);
  const day = `0${d.getDate()}`.slice(-2);
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const year = d.getFullYear();
  const hours = `0${d.getHours()}`.slice(-2);
  const minutes = `0${d.getMinutes()}`.slice(-2);

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

const TodayPage = (props: IProps) => {
  const [overall, setOverall] = useState<Order>();

  useEffect(() => {
    getTodayOverall().then((data) => setOverall(data));
  }, []);

  useEffect(() => {
    if (overall) {
      document.title = `Сегодня: ${overall.factInc}% / $${overall.amountMins}`;
    }
  }, [overall]);

  return (
    <div className="defaultLayout">
      <div className="flex justify-between flex-row">
        <p>
          Заработок за сегодня: <b>{overall?.factInc}%</b> /{" "}
          <b>${overall?.amountMins}</b>
        </p>
      </div>

      <TodayTable />
      <div className="mt-4 text-sm">
        <p>
          Обновлено:{" "}
          {overall?.createdAt
            ? formatDate(new Date(overall.createdAt))
            : formatDate(new Date())}
        </p>
      </div>
    </div>
  );
};

export default TodayPage;
