enum AppRoutes {
  Main = "/",
  UserCabinet = "/user_cabinet",
  UserPage = "/user_page",
  RegisterPage = "/register",
  LoginPage = "/login",
  TodayPage = "/today",
  ImportPage = "/import",
  IncomePage = "/income",
  DrawdownPage = "/drawdown",
  RobotPage = "/robot",
  MyPortfolio = "/my_portfolio",
  Portfolio = "/portfolio",
  Admin = "/admin",
}

export default AppRoutes;
