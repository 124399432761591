import { useEffect, useState } from "react";
import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";
import { getAllTable, getOverallAccTable } from "../network/TableApi/tableApi";
import { formatDate } from "../utils/functions";
import { TableOverallRes } from "../network/TableApi/tableInterfaces";

interface IProps {}

const OverallTable = (props: IProps) => {
  const [tableData, setTableData] = useState<Array<TableOverallRes>>();
  const [all, setAll] = useState<any>([]);
  const [money, setMoney] = useState<boolean>(false);

  useEffect(() => {
    getOverallAccTable({}).then((res) => {
      setTableData(res);
    });
    getAllTable({}).then((data) => {
      setAll(data);
    });
  }, []);

  const getColorStyle = (value: number) => {
    return value >= 0 ? { color: "#3ac47d" } : { color: "#d92550" };
  };

  const checkAccountStatus = (updated: string) => {
    const updatedTime = new Date(updated);
    const now = new Date();
    const timeDiff = (now.getTime() - updatedTime.getTime()) / 1000 / 60; // разница в минутах
    return timeDiff <= 30; // Если разница <= 30 минут, аккаунт считается онлайн
  };

  const renderedRows = tableData?.map((row) => {
    const rev = [...row.orders.overall].reverse();
    const revM = [...row.orders.month].reverse();
    const revD = [...row.orders.today].reverse();

    // Проверка статуса аккаунта
    const isOnline = row.updated ? checkAccountStatus(row.updated) : false;

    return (
      <TableRow
        key={row.account}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Link
            className="text-blue-500 underline"
            to={createPath({
              route: AppRoutes.RobotPage,
              args: [{ key: "id", value: row.account }],
            })}
          >
            <b>
              {row.name ? `${row.name} ${row.account.slice(-4)}` : row.account}
            </b>
          </Link>
          &nbsp;
          {row.updated && (
            <Tooltip title={isOnline ? "Аккаунт онлайн" : "Аккаунт оффлайн"}>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: isOnline ? "#3ac47d" : "#d92550",
                  marginLeft: "8px",
                }}
              />
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          {formatDate(row.orders.overall[0].CloseTime.split("T")[0])}
        </TableCell>
        <TableCell align="right" sx={getColorStyle(rev[0]?.factInc ?? 0)}>
          {money
            ? rev[0]
              ? `$${rev[0].amountMins.toFixed(2)}`
              : "$0.00"
            : rev[0]
            ? `${rev[0].factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
        <TableCell
          align="right"
          sx={getColorStyle(rev[0]?.amountCompound ?? 0)}
        >
          {money
            ? rev[0]
              ? `$${rev[0].amountMins.toFixed(2)}`
              : "$0.00"
            : (rev[0].amountCompound * 100).toFixed(2) + "%"}
        </TableCell>
        <TableCell align="right" sx={getColorStyle(revM[0]?.factInc ?? 0)}>
          {money
            ? revM[0]
              ? `$${(revM[0].amountMins / 100).toFixed(2)}`
              : "$0.00"
            : revM[0]
            ? `${revM[0].factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
        <TableCell align="right" sx={getColorStyle(revD[0]?.factInc ?? 0)}>
          {money
            ? revD[0]
              ? `$${(revD[0].amountMins / 100).toFixed(2)}`
              : "$0.00"
            : revD[0]
            ? `${revD[0].factInc.toFixed(2)}%`
            : "0%"}
        </TableCell>
      </TableRow>
    );
  });

  const isTableDataEmpty = tableData?.length === 0;
  const isAllEmpty = !all.data || all.data.overall.length === 0;

  return (
    <div>
      <div className="flex">
        <FormControlLabel
          value="start"
          checked={money}
          onChange={() => {
            setMoney(!money);
          }}
          control={<Switch color="primary" />}
          label="Показывать в деньгах: "
          labelPlacement="start"
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Название счета</b>
              </TableCell>
              <TableCell>
                <b>Дата старта</b>
              </TableCell>
              <TableCell align="right">
                <b>Фактический доход</b>
              </TableCell>
              <TableCell align="right">
                <b>Абсолютный доход</b>
              </TableCell>
              <TableCell align="right">
                <b>Фактический доход за месяц</b>
              </TableCell>
              <TableCell align="right">
                <b>Фактический доход за день</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isTableDataEmpty && isAllEmpty ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Нет данных для отображения
                </TableCell>
              </TableRow>
            ) : (
              <>
                {renderedRows}
                {all.data && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <b>Итого</b>
                    </TableCell>
                    <TableCell>~</TableCell>
                    <TableCell
                      align="right"
                      sx={getColorStyle(
                        all.data.overall[all.data.overall.length - 1]
                          ?.factInc ?? 0
                      )}
                    >
                      {money
                        ? all.data.overall
                          ? `$${all.data.overall[
                              all.data.overall.length - 1
                            ].amountMins.toFixed(2)}`
                          : "$0.00"
                        : all.data.overall
                        ? `${all.data.overall[
                            all.data.overall.length - 1
                          ].factInc.toFixed(2)}%`
                        : "0%"}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={getColorStyle(
                        all.data.overall[all.data.overall.length - 1]
                          ?.amountCompound ?? 0
                      )}
                    >
                      {money
                        ? all.data.overall
                          ? `$${all.data.overall[
                              all.data.overall.length - 1
                            ].amountMins.toFixed(2)}`
                          : "$0.00"
                        : all.data.overall
                        ? `${(
                            all.data.overall[all.data.overall.length - 1]
                              .amountCompound * 100
                          ).toFixed(2)}%`
                        : "0%"}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={getColorStyle(
                        all.data.month[all.data.month.length - 1]?.factInc ?? 0
                      )}
                    >
                      {money
                        ? all.data.month
                          ? `$${(
                              all.data.month[all.data.month.length - 1]
                                .amountMins / 100
                            ).toFixed(2)}`
                          : "$0.00"
                        : all.data.month
                        ? `${all.data.month[
                            all.data.month.length - 1
                          ].factInc.toFixed(2)}%`
                        : "0%"}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={getColorStyle(
                        all.data.today[all.data.today.length - 1]?.factInc ?? 0
                      )}
                    >
                      {money
                        ? all.data.today[all.data.today.length - 1]
                          ? `$${(
                              all.data.today[all.data.today.length - 1]
                                .amountMins / 100
                            ).toFixed(2)}`
                          : "$0.00"
                        : all.data.today[all.data.today.length - 1]
                        ? `${all.data.today[
                            all.data.today.length - 1
                          ].factInc.toFixed(2)}%`
                        : "0%"}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OverallTable;
