import { useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";

interface AccountDetail {
  month: number;
  year: number;
  margin?: number;
}

interface AccountMarginAndDrawdown {
  account: string;
  name?: string;
  details: AccountDetail[];
}

interface Props {
  data: AccountMarginAndDrawdown[];
  selectedYear: number;
  setSelectedYear: (year: number) => void;
}

const getMarginCellColor = (margin: number) => {
  if (margin === 0) return "#808080";
  if (margin > 1000) return "#3ac47d";
  if (margin >= 500 && margin <= 999) return "#f7b924";
  return "#d92550";
};

const YearlyMargin = ({ data, selectedYear, setSelectedYear }: Props) => {
  const activeMonths = useMemo(() => {
    const monthsSet = new Set<number>();
    data.forEach((row) => {
      row.details.forEach((detail) => {
        if (detail.year === selectedYear && detail.margin !== 0) {
          monthsSet.add(detail.month);
        }
      });
    });
    return Array.from(monthsSet).sort((a, b) => a - b);
  }, [data, selectedYear]);

  const renderedRows = useMemo(() => {
    return data.map((row) => {
      const marginByMonth = (month: number) => {
        const monthlyDetails = row.details.filter(
          (detail) => detail.month === month && detail.year === selectedYear
        );
        if (monthlyDetails.length === 0) return null;
        const minMargin = Math.min(
          ...monthlyDetails.map((detail) => detail.margin ?? 0)
        );
        return isNaN(minMargin) ? null : minMargin;
      };

      return (
        <TableRow key={row.account}>
          <TableCell component="th" scope="row">
            <Link
              className="text-blue-500 underline"
              to={createPath({
                route: AppRoutes.RobotPage,
                args: [{ key: "id", value: row.account }],
              })}
            >
              <b>
                {row.name
                  ? `${row.name} ${row.account.slice(-4)}`
                  : row.account}
              </b>
            </Link>
          </TableCell>
          {activeMonths.map((month) => {
            const margin = marginByMonth(month);
            const color = getMarginCellColor(margin || 0);
            return (
              <TableCell
                key={month}
                align="right"
                sx={{ color, fontWeight: "bold" }}
              >
                {margin !== null ? `${margin} %` : "—"}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  }, [data, activeMonths, selectedYear]);

  return (
    <div>
      <FormControl>
        <InputLabel id="year-select-label">Год</InputLabel>
        <Select
          labelId="year-select-label"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value as number)}
        >
          {Array.from({ length: 10 }, (_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="margin table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Название счета</b>
              </TableCell>
              {activeMonths.map((month) => (
                <TableCell key={month} align="right">
                  <b>
                    Минимальная маржа (
                    {new Date(0, month - 1).toLocaleString("ru-RU", {
                      month: "long",
                    })}
                    )
                  </b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderedRows}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default YearlyMargin;
