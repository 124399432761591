import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Order, RobotRes } from "../network/TableApi/tableInterfaces";
import {
  getSingle,
  getSingleAll,
  getSingleOverall,
} from "../network/TableApi/tableApi";
import RobotChart from "../components/RobotChart";
import MonthlyDrawdownChart from "../components/MonthlyDrawdownChart";
import MonthlyMarginChart from "../components/MonthlyMarginChart";
import SingleChart from "../components/Single/SingleChart";
import YearlyMargin from "../components/YearlyMargin";
import YearlyDrawdown from "../components/YearlyDrawdown";
import Spinner from "../components/Spinner";

interface IProps {}

const RobotPage = (props: IProps) => {
  const [overall, setOverall] = useState<{ name: string; orders: Order }>();
  const [robot, setRobot] = useState<RobotRes>();
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
    new Date().getMonth() + 1
  );
  const [all, setAll] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  useEffect(() => {
    if (id) {
      setLoading(true);
      getSingleOverall({ account: id }).then((data) => setOverall(data));
      getSingle({ account: id, year: selectedYear })
        .then((data) => setRobot(data))
        .finally(() => setLoading(false));
    }
  }, [id, selectedYear]);

  useEffect(() => {
    if (id) {
      getSingleAll({ id: id || "" }).then((data) => {
        const processedData = data.data.overall.map((item: any) => {
          const newItem = { ...item, CloseTime: item.CloseTime.split("T")[0] };
          return newItem;
        });
        setAll(processedData);
      });
    }
  }, [id]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(event.target.value));
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(Number(event.target.value));
  };

  const getLastOrder = (orders: Order[] | undefined): Order | undefined => {
    if (!orders || orders.length === 0) {
      return undefined;
    }
    const sortedOrders = orders
      .slice()
      .sort(
        (a, b) =>
          new Date(b.CloseTime).getTime() - new Date(a.CloseTime).getTime()
      );
    return sortedOrders[0];
  };

  const formatIncome = (value: number | undefined, overall?: boolean) => {
    if (value === undefined) return "Нет данных";
    const formattedValue = overall
      ? (value * 100).toFixed(2)
      : value.toFixed(2);
    const colorClass = value >= 0 ? "text-green-500" : "text-red-500";
    return <span className={`font-bold ${colorClass}`}>{formattedValue}%</span>;
  };

  const formatIncomeMoney = (value: number | undefined, overall?: boolean) => {
    if (value === undefined) return "Нет данных";
    const formattedValue = (overall ? value / 1 : value / 100).toFixed(2);
    const colorClass = value >= 0 ? "text-green-500" : "text-red-500";
    return <span className={`font-bold ${colorClass}`}>${formattedValue}</span>;
  };

  let formattedData;
  if (robot) {
    formattedData = {
      today: getLastOrder(robot.data.today),
      yesterday: getLastOrder(robot.data.yesterday),
      currentWeek: getLastOrder(robot.data.currentWeek),
      lastWeek: getLastOrder(robot.data.lastWeek),
      currentMonth: getLastOrder(robot.data.currentMonth),
      lastMonth: getLastOrder(robot.data.lastMonth),
    };
  }

  return (
    <div className="defaultLayout">
      {formattedData && (
        <div>
          <div>
            <div className="mb-6">
              <h1>
                Информация о роботе:{" "}
                <b>{overall?.name ? `${overall.name} ${id?.slice(-4)}` : id}</b>
              </h1>
            </div>
            <p>
              <b>Доходы по периодам:</b>
            </p>
            <p>
              Фактический доход за все время:{" "}
              {formatIncome(overall?.orders.factInc)}
            </p>
            <p>
              Абсолютный доход за все время:{" "}
              {formatIncome(overall?.orders.amountCompound, true) || 0}
            </p>
            <p>
              Абсолютный доход за все время:{" "}
              {all
                ? formatIncomeMoney(all[all.length - 1].amountMins, true)
                : 0}
            </p>
            <div className="flex gap-4">
              <div>
                <p>
                  Доход за сегодня:{" "}
                  {formatIncomeMoney(formattedData.today?.amountMins)}/
                  {formatIncome(formattedData.today?.factInc)}
                </p>
                <p>
                  Доход за текущую неделю:{" "}
                  {formatIncomeMoney(formattedData.currentWeek?.amountMins)}/{" "}
                  {formatIncome(formattedData.currentWeek?.factInc)}
                </p>
                <p>
                  Доход за текущий месяц:{" "}
                  {formatIncomeMoney(formattedData.currentMonth?.amountMins)}/{" "}
                  {formatIncome(formattedData.currentMonth?.factInc)}
                </p>
              </div>
              <div>
                <p>
                  Доход за вчера:{" "}
                  {formatIncomeMoney(formattedData.yesterday?.amountMins)}/{" "}
                  {formatIncome(formattedData.yesterday?.factInc)}
                </p>
                <p>
                  Доход за прошлую неделю:{" "}
                  {formatIncomeMoney(formattedData.lastWeek?.amountMins)}/
                  {formatIncome(formattedData.lastWeek?.factInc)}
                </p>
                <p>
                  Доход за прошлый месяц:{" "}
                  {formatIncomeMoney(formattedData.lastMonth?.amountMins)}/
                  {formatIncome(formattedData.lastMonth?.factInc)}
                </p>
              </div>
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="mt-4">
                <SingleChart
                  type={"amountCompound"}
                  desc="Сложный процент"
                  account={id || ""}
                />
              </div>
              <div className="mt-4">
                <SingleChart
                  type={"factInc"}
                  desc="Фактический процент"
                  account={id || ""}
                />
              </div>
              <div className="mt-4">
                <SingleChart
                  type={"amountMins"}
                  desc="Заработано"
                  account={id || ""}
                />
              </div>
              {robot?.data.year && (
                <div>
                  <div className="mt-4">
                    <label htmlFor="year-select">Выберите год:</label>
                    <select
                      id="year-select"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      {Array.from({ length: 10 }, (_, i) => {
                        const year = new Date().getFullYear() - i;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                    <RobotChart orders={robot?.data.year} />
                  </div>
                  <div className="mt-4">
                    <label htmlFor="month-select">Выберите месяц:</label>
                    <select
                      id="month-select"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      {robot?.data.yearlyDetails.map((detail: any) => (
                        <option key={detail.month} value={detail.month}>
                          {new Date(0, detail.month - 1).toLocaleString(
                            "ru-RU",
                            {
                              month: "long",
                            }
                          )}
                        </option>
                      ))}
                    </select>
                    {selectedMonth !== undefined && (
                      <>
                        <MonthlyDrawdownChart
                          details={
                            robot?.data.yearlyDetails.find(
                              (detail) => detail.month === selectedMonth
                            )?.details || []
                          }
                        />
                        <div className="mt-4">
                          <MonthlyMarginChart
                            details={
                              robot?.data.yearlyDetails.find(
                                (detail) => detail.month === selectedMonth
                              )?.details || []
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>

                  {/* <div>
                    <YearlyMargin account={id?.toString()} />
                  </div>
                  <div>
                    <YearlyDrawdown account={id?.toString()} />
                  </div> */}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RobotPage;
