import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { getAllAccTable } from "../network/TableApi/tableApi";
import { useEffect, useState } from "react";
import { TableAllRes } from "../network/TableApi/tableInterfaces";
import { Link } from "react-router-dom";
import { createPath } from "../utils/linkHandler";
import AppRoutes from "../structure/routes";

interface IProps {}

const TodayTable = (props: IProps) => {
  const [tableData, setTableData] = useState<Array<TableAllRes>>();

  useEffect(() => {
    getAllAccTable({ period: "day" }).then((res) => {
      setTableData(res);
    });
  }, []);

  const getMarginColor = (margin: number) => {
    if (margin > 1000) return "#3ac47d"; // зеленый
    if (margin >= 500) return "#f7b924"; // желтый
    return "#d92550"; // красный
  };

  const getDrawdownColor = (drawdown: number) => {
    if (drawdown < -30) return "#d92550"; // красный
    if (drawdown <= -20) return "#f7b924"; // желтый
    return "#3ac47d"; // зеленый
  };

  const getColorStyle = (value: number) => {
    return value >= 0 ? { color: "#3ac47d" } : { color: "#d92550" };
  };

  const renderedRows = tableData?.map((row) => {
    const rev = [...row.orders].reverse();
    const margin = rev[0] ? rev[0].margin : 0;
    const drawdown = rev[0] ? rev[0].drawdown : 0;

    return (
      <TableRow
        key={row.account}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Link
            className="text-blue-500 underline"
            to={createPath({
              route: AppRoutes.RobotPage,
              args: [{ key: "id", value: row.account }],
            })}
          >
            <b>
              {row.name ? `${row.name} ${row.account.slice(-4)}` : row.account}
            </b>
          </Link>
        </TableCell>
        <TableCell align="right">
          ${row.balance !== 0 ? (row.balance / 100).toFixed(2) : `н/д`}
        </TableCell>
        <TableCell align="right">
          {row.equity !== 0 ? ` $${(row.equity / 100).toFixed(2)}` : " н/д"}
        </TableCell>
        <TableCell align="right" sx={getColorStyle(rev[0]?.amountMins ?? 0)}>
          {rev[0] ? (rev[0].amountMins / 100).toFixed(2) : 0}
        </TableCell>
        <TableCell align="right" sx={getColorStyle(rev[0]?.factInc ?? 0)}>
          {rev[0] ? rev[0].factInc.toFixed(2) : 0}
        </TableCell>
        <TableCell align="right" sx={{ color: getMarginColor(row.margin) }}>
          {row.margin}
        </TableCell>
        <TableCell align="right" sx={{ color: getDrawdownColor(row.drawdown) }}>
          {row.drawdown}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 750 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Название счета</b>
            </TableCell>
            <TableCell>
              <b>Баланс</b>
            </TableCell>
            <TableCell>
              <b>Эквити</b>
            </TableCell>
            <TableCell align="right">
              <b>Сегодня $</b>
            </TableCell>
            <TableCell align="right">
              <b>Сегодня %</b>
            </TableCell>
            <TableCell align="right">
              <b>Маржа</b>
            </TableCell>
            <TableCell align="right">
              <b>Просадка</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderedRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TodayTable;
