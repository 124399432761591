import { useEffect, useState } from "react";
import { getPortfolio, getPortfolios } from "../network/TableApi/tableApi";
import {
  PortfolioRes,
  SinglePortfolioRes,
} from "../network/TableApi/tableInterfaces";
import ProtfolioSelector from "../components/Portfolio/ProtfolioSelector";
import PortfolioChart from "../components/Portfolio/PortfolioChart";
import PortfolioTable from "../components/Portfolio/PortfolioTable";
import PortfolioModal from "../components/Portfolio/PortfolioModal";
import { getHeader } from "../network/UserApi/userApi";
import { useAuth } from "../providers/AuthProvider";
import EditPortfolioModal from "../components/Portfolio/EditPortfolioModal";

interface IProps {}

const MyPortfolioPage = (props: IProps) => {
  const [portfolios, setPortfolios] = useState<PortfolioRes | null>(null);
  const [selected, setSelected] = useState<string>();
  const [name, setName] = useState<string>("Название робота");
  const [loaded, setLoaded] = useState<SinglePortfolioRes>();
  const [reloadKey, setReloadKey] = useState<number>(0);
  const [userInfo, setUserInfo] = useAuth();

  function getUserPortfolios(id?: string) {
    getPortfolios().then((res: PortfolioRes) => {
      setPortfolios(res);
      setSelected(id ? id : res.data[0].id || "");
    });
  }

  useEffect(() => {
    if (selected) {
      getPortfolio({ id: selected }).then((res: SinglePortfolioRes) => {
        setName(res.data.name || "");
        setLoaded(res);
      });
    }
  }, [selected, reloadKey]);

  useEffect(() => {
    getUserPortfolios();
    getHeader().then((r) => {
      setUserInfo(r);
    });
  }, []);

  return (
    <div className="defaultLayout min-w-[900px]">
      <div className="flex items-center gap-4">
        <div>
          {portfolios ? (
            portfolios.data.length > 0 ? (
              <div>
                <p>Выберите портфель:</p>
                <ProtfolioSelector
                  portfolios={portfolios.data}
                  selected={loaded?.data.name || "Портфель"}
                  onChange={(e) => {
                    setSelected(e);
                  }}
                />
              </div>
            ) : (
              <p>Нет данных для отображения</p>
            )
          ) : (
            <p>Загрузка...</p>
          )}
        </div>
        {userInfo.accounts && (
          <div>
            <PortfolioModal
              accounts={userInfo.accounts}
              onCreate={(e) => {
                if (e) {
                  getUserPortfolios();
                }
              }}
            />
          </div>
        )}
        {loaded?.data.id === selected && loaded && userInfo.accounts && (
          <EditPortfolioModal
            id={loaded.data.id}
            name={loaded.data.name || ""}
            loaded={loaded.accounts}
            accounts={userInfo.accounts}
            onCreate={(e) => {
              if (e) {
                getUserPortfolios(selected);
                setReloadKey((prevKey) => prevKey + 1); // Изменяем состояние
              }
            }}
          />
        )}
      </div>
      {selected && (
        <div className="defaultLayout">
          <div className="font-bold text-lg mb-4">
            <h2>Портфель: {name}</h2>
          </div>
          <div>
            <PortfolioTable key={reloadKey} id={selected} />
            <div className="flex flex-col mt-10">
              <p className="mb-4">Графики за все время</p>
              <PortfolioChart
                key={`${reloadKey}-amountCompound`}
                id={selected}
                type={"amountCompound"}
                desc="Сложный процент"
              />
              <PortfolioChart
                key={`${reloadKey}-factInc`}
                id={selected}
                type={"factInc"}
                desc="Фактический процент"
              />
              <PortfolioChart
                key={`${reloadKey}-amountMins`}
                id={selected}
                type={"amountMins"}
                desc="Заработано"
              />
              <PortfolioChart
                key={`${reloadKey}-balanceBefore`}
                id={selected}
                type={"balanceBefore"}
                desc="Баланс"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyPortfolioPage;
